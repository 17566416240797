var requestList = (function() {
    reqList = [];

    function Product(id, name, description, type, image, amount, target_price, note, min_amount, max_amount) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.type = type;
        this.image = image;
        this.amount = amount;
        this.target_price = target_price;
        this.note = note;
        this.min_amount = min_amount;
        this.max_amount = max_amount;
    }

    function saveRequestList() {
        sessionStorage.setItem('requestList', JSON.stringify(reqList));
    }

    function loadRequestList() {
        reqList = JSON.parse(sessionStorage.getItem('requestList'));
    }
    if (sessionStorage.getItem("requestList") != null) {
        loadRequestList();
    }

    var obj = {};

    obj.addItemToRequestList = function(id, name, description, type, image, amount, target_price, note, min_amount, max_amount) {
        var item = new Product(id, name, description, type, image, amount, target_price, note, min_amount, max_amount);
        reqList.push(item);
        saveRequestList();
    }

    obj.listRequestList = function() {
        var cartCopy = [];
        for(i in reqList) {
            item = reqList[i];
            itemCopy = {};
            for(p in item) {
                itemCopy[p] = item[p];
            }
            cartCopy.push(itemCopy)
        }
        return cartCopy;
    }

    // Remove item from cart
    obj.removeItemFromRequestList = function(id) {
        for(var item in reqList) {
            if(reqList[item].id === id) {
                reqList.splice(item, 1);
            }
        }
        saveRequestList();
    }

    // Update item from cart
    obj.updateItemFromRequestList = function(id, amount) {
        for(var item in reqList) {
            if(reqList[item].id === id) {
                if(amount < reqList[item].min_amount){
                    console.error("must not update amount to: " + amount + " because min limit is: " + reqList[item].min_amount);
                }
                else{
                    console.log("update amount: " + reqList[item].amount + " in list to: " + amount + " for product: " + reqList[item].name);
                    reqList[item].amount = amount;
                }
            }
        }
        saveRequestList();
    }

    obj.getInputAmountValue = function(id) {
        for(var item in reqList) {
            if (reqList[item].id === id) {
                return reqList[item].amount;
            }
        }
    }

    // Count cart
    obj.totalCount = function() {
        var totalCount = 0;
        for(var item in reqList) {
            totalCount++;
        }
        return totalCount;
    }

    obj.clearCart = function() {
        reqList = [];
        saveRequestList();
        updateRequestListCounter();
    }

    return obj;
})();

function displayRequestList() {
    var cartArray = requestList.listRequestList();
    var output = "";
    for(var i in cartArray) {
        output += "<div class='cart-product'>"
            + "<div class='image'><img src='" + cartArray[i].image + "' alt='' /></div>"
            + "<div class='description'>"
            + "<div>"
            + "<h4 class='product-title'>" + cartArray[i].name + "</h4>"
            + "<p class='settings'>"  + cartArray[i].description + "</p>"
            + "<div class='actions'>";

        if(cartArray[i].type !== "sports-nutrition"){
            output += "<div class='custom-amount-picker-small'>"
                + "<button class='decrease' data-item='" + cartArray[i].id + "'>-</button>"
                + "<input type='number' data-item='" + cartArray[i].id + "' value='" + cartArray[i].amount + "' min='50' class='amount-input'/>"
                + "<button class='increase' data-item='" + cartArray[i].id + "'>+</button>"
                + "</div>";
        }

        output += "<button class='remove delete-item' data-item='" + cartArray[i].id + "'>Entfernen</button>"
            + "</div>"
            + "</div>"
            + "</div>"
            + "</div>";
    }
    $('.cart-counter').html(requestList.totalCount());
    $('.cart-product-list').html(output);
}
function updateRequestListCounter() {
    $('.cart-counter').html(requestList.totalCount());
}



$("#productForm").submit(function(e) {
    e.preventDefault();

    var formData = new FormData(e.target);
    console.log(Object.fromEntries(formData));
    const image = formData.get("image");
    const id = formData.get("id") + "-" + (new Date()).getTime();
    const name = formData.get("name");

    const o_package = formData.get("package");
    const glas_color = formData.get("glas_color");
    const fill_amount = formData.get("fill_amount");
    const type = formData.get("type");
    const target_price = formData.get("target_price");
    const note = formData.get("note");

    const amount = formData.get("amount");

    var description = "";
    if(o_package != null){
        description += o_package + ", ";
    }
    if(glas_color != null){
        description += glas_color + ", ";
    }
    if(fill_amount != null){
        description += fill_amount + ", ";
    }

    if(type === "sports-nutrition"){
        description += "Abnahmemenge: " + amount + "kg, ";
    }

    // Trim last colon
    if(description.length > 2){
        description = description.slice(0, -2);
    }

    //alternate serialize form with jQuery
    //console.log($(e.target).serializeArray());

    requestList.addItemToRequestList(id, name, description, type, image, amount, target_price, note, 50, 10000);
    updateRequestListCounter();

    $(this).each(function(){
        console.log($(this).find(':input')); //<-- Should return all input elements in that specific form.
    });

    displayRequestList();

    $(".cart-drawer").addClass("show");
    $('.bg-overlay-cart').addClass("show");
});

$(".cart-icon").click(function(){
    $(".cart-drawer").addClass("show");
    $('.bg-overlay-cart').addClass("show");
    displayRequestList();
});

//events
$('.cart-product-list').on("click", ".delete-item", function(event) {
    var id = $(this).data('item')

    requestList.removeItemFromRequestList(id);
    displayRequestList();
});

$(".cart-product-list").on("input", ".amount-input", function() {
    var id = $(this).data('item');
    var amount = parseInt($(".amount-input").val());

    var inputAmount = $(".amount-input");

    console.log("change input value to: " + amount + " for product with id: " + id);

    requestList.updateItemFromRequestList(id, amount);
    displayRequestList();
});

//methods for changing order amounts
$('.cart-product-list').on("click", ".increase", function(event) {
    var id = $(this).data('item');
    var increaseAmount = parseInt(requestList.getInputAmountValue(id));
    increaseAmount+=5;

    console.log("increase amount to: " + increaseAmount + " for product with id: " + id);
    $(this).siblings(".amount-input").val(increaseAmount);

    requestList.updateItemFromRequestList(id, increaseAmount);
    displayRequestList();
});

$('.cart-product-list').on("click", ".decrease", function(event) {
    var id = $(this).data('item');
    var decreaseAmount = parseInt(requestList.getInputAmountValue(id));
    decreaseAmount-=5;

    console.log("trying decreasing amount to: " + decreaseAmount +  " for product with id: " + id);
    $(this).siblings(".amount-input").val(decreaseAmount);

    requestList.updateItemFromRequestList(id, decreaseAmount);
    displayRequestList();
});



displayRequestList();
updateRequestListCounter();
