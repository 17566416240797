require('./bootstrap');
require('./cart');

function getWhitelabelCartList() {
    return JSON.parse(sessionStorage.getItem('whitelabelCartList'));
}

//function calculates the total price for a product (according to selected options)
function getCalculatedTotalPrice(productId) {
    var addonPrice = 0;
    var unitPrice = Number(document.getElementById("unit-price-"+productId).value.replace(",", "."));
    var amount = Number(document.getElementById("amount-"+productId).value);

    //loop through and get addon prices of product
    var parseD = JSON.parse(document.getElementById("addon-prices-"+productId).value);
    for (const [index, element] of parseD.entries()) {
        //parseD[index][0] = option-id
        //parseD[index][1] = description of option
        //parseD[index][2] = addon price of option
        if(parseD[index][2] > 0){
            addonPrice += Number(parseD[index][2]); //add addon prices to total price
        }
    }

    return (unitPrice+addonPrice)*amount;
}

$(document).ready(function(){
    //console.log(getWhitelabelCartList());
    $(".hamburger").click(function(){
        $(".hamburger").toggleClass("is-active");
        $('.nav-links').toggleClass("show");
        $('body').toggleClass("menu-open");
        $('.mainnav').toggleClass("menu-open");
    });

    $(".toggle-dropdown").click(function(){
        $(this).toggleClass("flip");
        $(this).siblings('.nav-dropdown').slideToggle();
    });



    //Whitelabel Dropdown Custom
    $(".toggle-dropdown-btn").click(function(){
        if ($(this).hasClass("open")) {
            $(this).removeClass("open");
            $(this).siblings('.dropdown').removeClass("show");
        } else {
            //Close all others
            $(".toggle-dropdown-btn").removeClass("open");
            $(".custom-option-dropdown").removeClass("show");
            //Open this
            $(this).toggleClass("open");
            $(this).siblings('.dropdown').toggleClass("show");
        }

    });

    $(".custom-option-dropdown-element").click(function(){
        //Close all others
        $(".toggle-dropdown-btn").removeClass("open");
        $(".custom-option-dropdown").removeClass("show");

        //Set data attribute of addon price on product
        $("#"+$(this).data("parent-product-id")+"-single-price-label").attr('data-addon-price-single', $(this).data('addon-price'));

        if($(this).data("addon-price") > 0){
            //Write Value into active (main)field and add label
            $("#"+$(this).data("parent-option-id")+"-value-label").text($(this).data("option-value")).append(" <span class='text-gold'>+" + $(this).data('addon-price') + "€ / Stück</span>");
        }else{
            //Write Value into active (main)field
            $("#"+$(this).data("parent-option-id")+"-value-label").text($(this).data("option-value"));
        }

        //set hidden input fields for custom option
        $("#addon-label-"+$(this).data("parent-option-id")).val($(this).data("option-value"));
        $("#addon-price-"+$(this).data("parent-option-id")).val($(this).data("addon-price"));

        //start adding custom option to addon price array
        var parseD = JSON.parse(document.getElementById("addon-prices-" + $(this).data("parent-product-id")).value);
        for (const [index, element] of parseD.entries()) {
            //check if entry for option already exists in the array, if so -> delete
            if(parseD[index][0] === $(this).data("parent-option-id")){
                parseD.splice(index, 1);
                break;
            }
        }
        parseD.push([$(this).data("parent-option-id"), $(this).data("option-value"), $(this).data("addon-price")]);
        var stringD = JSON.stringify(parseD);
        $("#addon-prices-"+$(this).data("parent-product-id")).val(stringD);
        //end adding custom option to addon price array

        //calculate new single price for product
        productSinglePrice = "#"+$(this).data("parent-product-id")+"-single-price-label";
        newSinglePrice = parseFloat($(productSinglePrice).data('single-price')) + parseFloat($(productSinglePrice).data('addon-price-single'));
        //Replace old single value with new value
        $(productSinglePrice).text(newSinglePrice.toFixed(2).toString().replace('.', ','));
    });

    $(".custom-option-price-element").click(function(){
        //Close all others
        $(".toggle-dropdown-btn").removeClass("open");
        $(".custom-option-dropdown").removeClass("show");

        $("#amount-"+$(this).data("parent-product-id")).val($(this).data("amount"));
        $("#unit-price-"+$(this).data("parent-product-id")).val($(this).data("unit-price"));

        //Write Value into active (main)field
        $("#"+$(this).data("parent-product-id")+"-active-value-number").text($(this).data("amount"));

        //Update line item price for product
        $("#"+$(this).data("parent-product-id")+"-single-price-label").text($(this).data("unit-price"));
    });

    $(".custom-option-dropdown-element, .custom-option-price-element").click(function(){
        //Calculate new total product price
        var totalPrice = getCalculatedTotalPrice($(this).data("parent-product-id"));
        var totalPriceLabel = $("#"+$(this).data("parent-product-id")+"-total-price-label");
        totalPriceLabel.attr('data-total-price', totalPrice);
        totalPriceLabel.text(totalPrice.toFixed(2).toString().replace('.', ','));
    });

    //End Custom Dropdown





    $(".cart-close-button").click(function(){
        $(".cart-drawer").removeClass("show");
        $('.bg-overlay-cart').removeClass("show");
    });
    $(".bg-overlay-cart").click(function(){
        $(".cart-drawer").removeClass("show");
        $('.bg-overlay-cart').removeClass("show");
    });

    $(".preview-image").click(function(){
        $('.main-image').removeClass("active");
        $('.main-'+$(this).attr("data-index")).addClass("active");
        $('.preview-image').removeClass("active");
        $(this).addClass("active");
    });

    $(".sliding-link").click(function(e) {
        e.preventDefault();
        var aid = $(this).attr("href");
        $('html,body').animate({scrollTop: ($(aid).offset().top - 200)},'slow');
    });

    $(".has-dropdown").hover(function(){
        $(".bg-overlay-menu").addClass("show");
    }, function () {
        $(".bg-overlay-menu").removeClass("show");
    });

    const sections = document.getElementsByClassName("category-row");
    const navLi = document.getElementsByClassName("sticky-nav-link");

    window.onscroll = () => {
        var current = "";

        for (let section of sections) {
            const sectionTop = section.offsetTop;
            if (pageYOffset >= sectionTop - 220) {
                current = section.getAttribute("id"); }
        }

        for (let li of navLi) {
            li.classList.remove("active");
            if (li.classList.contains(current)) {
                li.classList.add("active");
            }
        }

    };


    //Account Drawer
    $(".toggle-drawer").click(function(){
        $($(this).data("target")).addClass("show");
        $('.bg-overlay-drawer').addClass("show");
    });
    $(".bg-overlay-drawer").click(function(){
        $(".drawer").removeClass("show");
        $('.bg-overlay-drawer').removeClass("show");
    });
    $(".drawer-close-button").click(function(){
        $(".drawer").removeClass("show");
        $('.bg-overlay-drawer').removeClass("show");
    });
});
