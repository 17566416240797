require('./bootstrap');
require('./cart');

$(document).ready(function(){

    $(".hamburger").click(function(){
        $(".hamburger").toggleClass("is-active");
        $('.nav-links').toggleClass("show");
        $('body').toggleClass("menu-open");
        $('.mainnav').toggleClass("menu-open");
    });

    $(".toggle-dropdown").click(function(){
        $(this).toggleClass("flip");
        $(this).siblings('.nav-dropdown').slideToggle();
    });


    $(".cart-close-button").click(function(){
        $(".cart-drawer").removeClass("show");
        $('.bg-overlay-cart').removeClass("show");
    });
    $(".bg-overlay-cart").click(function(){
        $(".cart-drawer").removeClass("show");
        $('.bg-overlay-cart').removeClass("show");
    });

    $(".preview-image").click(function(){
        $('.main-image').removeClass("active");
        $('.main-'+$(this).attr("data-index")).addClass("active");
        $('.preview-image').removeClass("active");
        $(this).addClass("active");
    });

    $(".sliding-link").click(function(e) {
        e.preventDefault();
        var aid = $(this).attr("href");
        $('html,body').animate({scrollTop: ($(aid).offset().top - 200)},'slow');
    });

    $(".has-dropdown").hover(function(){
        $(".bg-overlay-menu").addClass("show");
    }, function () {
        $(".bg-overlay-menu").removeClass("show");
    });

    const sections = document.getElementsByClassName("category-row");
    const navLi = document.getElementsByClassName("sticky-nav-link");

    window.onscroll = () => {
        var current = "";

        for (let section of sections) {
            const sectionTop = section.offsetTop;
            if (pageYOffset >= sectionTop - 220) {
                current = section.getAttribute("id"); }
        }

        for (let li of navLi) {
            li.classList.remove("active");
            if (li.classList.contains(current)) {
                li.classList.add("active");
            }
        }

    };

});
